import Vue from "vue";
import store from "@/store/store.js";
import VueRouter from "vue-router";
import { Role } from "@/acl/roles";
import config from "@/config";
import { i18n } from "@/main.js";
import {
  productsRoutes,
  embeddedProductsRoutes,
  productsFactsheetGenerateRoutes,
} from "./products";
import { buildPathWithLang, beforeEnterChangeLocale } from "./utils";

Vue.use(VueRouter);

// =============================================================================
// HOME ROUTES
// =============================================================================

let mainLayoutRoutes = [
  {
    path: buildPathWithLang("/"),
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      authorize: config.allowedRoles,
      allowAdminOfOneProduct: true,
    },
    beforeEnter: beforeEnterChangeLocale,
  },
  ...productsRoutes,
  ...(config.customSections || [])
    .map((customSection) =>
      customSection.subSections.map((subSection) => subSection.router)
    )
    .flat(),
];

// If the platform was built with langInUrl, but somebody tries to access the home page without
// a lang, we redirect to the home page with a lang in the url.
if (config.langInUrl) {
  mainLayoutRoutes.push({
    path: "",
    redirect: `/${config.availableLanguages[0]}`,
  });
}

// It's possible we used to have routes that we removed from our defined urls,
// but some external website still references them. To avoid error when following
// those old urls, we redirect them to valid ones.
if (config.oldAlternativeUrlRedirection) {
  config.oldAlternativeUrlRedirection.forEach((toRedirect) => {
    mainLayoutRoutes.push({
      path: buildPathWithLang(toRedirect.oldUrl),
      redirect: buildPathWithLang(toRedirect.newUrl),
      beforeEnter: beforeEnterChangeLocale,
    });
  });
}

// =============================================================================
// HELP ROUTES
// =============================================================================

// Add the help routes we configured.
if (config.help) {
  if (config.help.faq) {
    mainLayoutRoutes.push({
      path: buildPathWithLang("/help/faq"),
      name: "help_faq",
      component: () => import("../views/help/Faq.vue"),
      meta: {
        authorize: config.allowedRoles,
        allowAdminOfOneProduct: true,
      },
      beforeEnter: beforeEnterChangeLocale,
    });
  }

  if (config.help.knowledgeBase) {
    mainLayoutRoutes = mainLayoutRoutes.concat([
      {
        path: buildPathWithLang("/help/knowledge-base"),
        name: "help_knowledgeBase",
        component: () => import("../views/help/KnowledgeBase.vue"),
        meta: {
          authorize: config.allowedRoles,
          allowAdminOfOneProduct: true,
        },
        beforeEnter: beforeEnterChangeLocale,
      },
      {
        path: buildPathWithLang("/help/knowledge-base/terms/:term"),
        name: "help_knowledgeBase_term",
        component: () =>
          import("../views/help/knowledgeBaseTerm/KnowledgeBaseTerm.vue"),
        meta: {
          authorize: config.allowedRoles,
          allowAdminOfOneProduct: true,
        },
        beforeEnter: beforeEnterChangeLocale,
        props: true,
      },
    ]);
  }

  if (config.help.contact) {
    mainLayoutRoutes.push({
      path: buildPathWithLang("/help/contact"),
      name: "help_contact",
      component: () => import("../views/help/Contact.vue"),
      meta: {
        authorize: config.allowedRoles,
        allowAdminOfOneProduct: true,
      },
      beforeEnter: beforeEnterChangeLocale,
    });
  }
}

// =============================================================================
// ADMIN ROUTES
// =============================================================================

mainLayoutRoutes.push({
  path: buildPathWithLang("/admin/users"),
  name: "admin_users",
  component: () => import("../views/admin/Users.vue"),
  meta: {
    authorize: [Role.admin, Role.superAdmin],
  },
  beforeEnter: beforeEnterChangeLocale,
});
mainLayoutRoutes.push({
  path: buildPathWithLang("/admin/products"),
  name: "admin_products",
  component: () => import("../views/admin/products/Products.vue"),
  meta: {
    authorize: [Role.admin, Role.superAdmin],
    allowAdminOfOneProduct: true,
  },
  beforeEnter: beforeEnterChangeLocale,
});
mainLayoutRoutes.push({
  path: buildPathWithLang("/admin/platform-settings"),
  name: "admin_platformSettings",
  component: () => import("../views/admin/PlatformSettings.vue"),
  meta: {
    authorize: [Role.admin, Role.superAdmin],
  },
  beforeEnter: beforeEnterChangeLocale,
});
mainLayoutRoutes.push({
  path: buildPathWithLang("/admin/operations"),
  name: "admin_operations",
  component: () => import("../views/admin/operations/Operations.vue"),
  meta: {
    authorize: [Role.admin, Role.superAdmin],
  },
  beforeEnter: beforeEnterChangeLocale,
});

// =============================================================================
// SETTINGS ROUTES
// =============================================================================

mainLayoutRoutes.push({
  path: buildPathWithLang("/settings/oveview"),
  name: "settings_overview",
  component: () => import("../views/settings/Settings.vue"),
  meta: {
    authorize: [Role.insight, Role.employee, Role.admin, Role.superAdmin],
    noNeedOfInvestorProfile: true,
  },
  beforeEnter: beforeEnterChangeLocale,
  props: {
    sectionId: "overview",
  },
});
mainLayoutRoutes.push({
  path: buildPathWithLang("/settings/edit-profile"),
  name: "settings_editProfile",
  component: () => import("../views/settings/Settings.vue"),
  meta: {
    authorize: [Role.insight, Role.employee, Role.admin, Role.superAdmin],
    noNeedOfInvestorProfile: true,
  },
  beforeEnter: beforeEnterChangeLocale,
  props: (route) => ({
    sectionId: "editProfile",
    askVerification: route.query?.askVerification ? true : false,
  }),
});
mainLayoutRoutes.push({
  path: buildPathWithLang("/settings/change-password"),
  name: "settings_changePassword",
  component: () => import("../views/settings/Settings.vue"),
  meta: {
    authorize: [Role.insight, Role.employee, Role.admin, Role.superAdmin],
    noNeedOfInvestorProfile: true,
  },
  beforeEnter: beforeEnterChangeLocale,
  props: {
    sectionId: "changePassword",
  },
});
mainLayoutRoutes.push({
  path: buildPathWithLang("/settings/delete-account"),
  name: "settings_deleteAccount",
  component: () => import("../views/settings/Settings.vue"),
  meta: {
    authorize: [Role.insight, Role.employee, Role.admin, Role.superAdmin],
    noNeedOfInvestorProfile: true,
  },
  beforeEnter: beforeEnterChangeLocale,
  props: {
    sectionId: "deleteAccount",
  },
});
mainLayoutRoutes.push({
  path: buildPathWithLang("/settings/preferences"),
  name: "settings_preferences",
  component: () => import("../views/settings/Settings.vue"),
  meta: {
    authorize: [Role.admin, Role.superAdmin],
    noNeedOfInvestorProfile: true,
  },
  beforeEnter: beforeEnterChangeLocale,
  props: {
    sectionId: "preferences",
  },
});

const routes = [
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
    path: "",
    component: () => import("../layouts/main/Main.vue"),
    children: mainLayoutRoutes,
  },
  // =============================================================================
  // PAGES
  // =============================================================================
  {
    // =============================================================================
    // Factsheet pages to generate the pdfs
    // =============================================================================
    path: "",
    component: () => import("@/layouts/full-page/FullPageBasic.vue"),
    children: productsFactsheetGenerateRoutes,
  },
  {
    path: "",
    component: () => import("@/layouts/full-page/FullPage.vue"),
    children: [
      // =============================================================================
      // Auth Routes
      // =============================================================================
      {
        path: buildPathWithLang("/auth/login"),
        name: "page-login",
        component: () => import("@/views/auth/login/Login.vue"),
        meta: {
          authorize: [Role.guest],
          noNeedOfInvestorProfile: true,
        },
        beforeEnter: beforeEnterChangeLocale,
        props: true,
      },
      {
        path: buildPathWithLang("/auth/login-2fa"),
        name: "page-login-2fa",
        component: () => import("@/views/auth/login/Login2fa.vue"),
        meta: {
          authorize: [Role.guest],
          noNeedOfInvestorProfile: true,
        },
        beforeEnter: beforeEnterChangeLocale,
        props: true,
      },
      {
        path: buildPathWithLang("/auth/register"),
        name: "page-register",
        component: () => import("@/views/auth/register/Register.vue"),
        meta: {
          authorize: [Role.guest],
          noNeedOfInvestorProfile: true,
        },
        beforeEnter: beforeEnterChangeLocale,
      },
      {
        path: buildPathWithLang("/auth/register-2fa"),
        name: "page-register-2fa",
        component: () => import("@/views/auth/register/Register2fa.vue"),
        meta: {
          authorize: [Role.insight],
          noNeedOfInvestorProfile: true,
        },
        beforeEnter: beforeEnterChangeLocale,
      },
      {
        // Note that we don't use 'buildPathWithLang' because this link is agnostic of language.
        path: "/auth/handled-by-firebase",
        beforeEnter(to) {
          // We want to keep the query params and add them to the Firebase URL.
          const allQueryParams = Object.keys(to.query).map(
            (key) => `${key}=${to.query[key]}`
          );
          const queryParamsStr = allQueryParams.join("&");
          // Put the full page url including the protocol http(s) below
          const authDomain = process.env.VUE_APP_FIREBASE_AUTH_DOMAIN;
          window.location = `https://${authDomain}/__/auth/action?${queryParamsStr}`;
        },
        meta: {
          noNeedOfInvestorProfile: true,
        },
      },
      {
        path: buildPathWithLang("/auth/reset-password-form"),
        name: "reset-password-form",
        component: () =>
          import("@/views/auth/resetPassword/ResetPasswordForm.vue"),
        meta: {
          authorize: [Role.guest],
          noNeedOfInvestorProfile: true,
        },
        beforeEnter: beforeEnterChangeLocale,
      },
      // =============================================================================
      // Compliance Routes
      // =============================================================================
      {
        path: buildPathWithLang("/compliance/terms-of-use"),
        name: "compliance_termsofuse",
        component: () => import("@/views/compliance/TermsOfUse.vue"),
        meta: {
          noNeedOfInvestorProfile: true,
        },
        beforeEnter: beforeEnterChangeLocale,
      },
      {
        path: buildPathWithLang("/compliance/privacy-policy"),
        name: "compliance_privacypolicy",
        component: () =>
          config.customPrivacyPolicyPath
            ? import(`@/views/${config.customPrivacyPolicyPath}`)
            : import("@/views/compliance/PrivacyPolicy.vue"),
        meta: {
          noNeedOfInvestorProfile: true,
        },
        beforeEnter: beforeEnterChangeLocale,
      },
      {
        path: buildPathWithLang("/compliance/ombudsman"),
        name: "compliance_ombudsman",
        component: () => import("@/views/compliance/Ombudsman.vue"),
        meta: {
          noNeedOfInvestorProfile: true,
        },
        beforeEnter: beforeEnterChangeLocale,
      },
      {
        path: buildPathWithLang("/investor-profile"),
        name: "page-investor-profile",
        component: () => import("@/views/compliance/InvestorProfile.vue"),
        meta: {
          noNeedOfInvestorProfile: true,
        },
        beforeEnter: beforeEnterChangeLocale,
      },
      {
        path: buildPathWithLang("/unauthorized-investor-profile"),
        name: "page-unauthorized-investor-profile",
        component: () =>
          import("@/views/errors/UnauthorizedInvestorProfile.vue"),
        meta: {
          noNeedOfInvestorProfile: true,
        },
        beforeEnter: beforeEnterChangeLocale,
        props: true,
      },

      // =============================================================================
      // Error Routes
      // =============================================================================
      {
        path: buildPathWithLang("/error-404"),
        name: "page-error-404",
        component: () => import("@/views/errors/Error404.vue"),
        meta: {
          noNeedOfInvestorProfile: true,
        },
        beforeEnter: beforeEnterChangeLocale,
      },
      {
        path: buildPathWithLang("/error-403"),
        name: "page-error-403",
        component: () => import("@/views/errors/Error403.vue"),
        meta: {
          noNeedOfInvestorProfile: true,
        },
        beforeEnter: beforeEnterChangeLocale,
        props: true,
      },
    ],
  },
  {
    // =============================================================================
    // EMBEDDED ROUTES
    // =============================================================================
    path: "",
    component: () => import("../layouts/embedded/Embedded.vue"),
    children: embeddedProductsRoutes,
  },
  // Since we now don't have the server at the same URL as the client, let's redirect any
  // requests that are using the old URL, as some people maybe have old links.
  {
    path: "/api/*",
    beforeEnter(to) {
      // We want to keep the query params and add them to the Firebase URL.
      const allQueryParams = Object.keys(to.query).map(
        (key) => `${key}=${to.query[key]}`
      );
      const queryParamsStr = allQueryParams.join("&");
      window.location = `${process.env.VUE_APP_API_URL}${to.path}?${queryParamsStr}`;
    },
    meta: {
      noNeedOfInvestorProfile: true,
    },
  },
  {
    path: "*",
    redirect: "/error-404",
    meta: {
      noNeedOfInvestorProfile: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach(async (to, from, next) => {
  // The first element we check is if the page is accessible to the user.
  // If they try to access something they shouldn't have access too, they
  // will be redirected to a 403.
  const {
    authorize,
    allowAdminOfOneProduct,
    allowAdminOfOneShareClass,
    productId,
  } = to.meta;

  // The document manager (Puppeteer) sends a custom
  // token to sign in from a headless browser.
  if (to.query.customFirebaseTokenForPuppeteer) {
    // Log in with the token passed in.
    await store.dispatch(
      "auth/signInWithCustomToken",
      to.query.customFirebaseTokenForPuppeteer.toString()
    );
  }

  await store.dispatch("userInfo/setAndGetUserRoles");

  // If 2FA is enabled and the user is logged in but hasn't registered 2FA,
  // redirect them to the 2FA registration page.
  if (
    config.enable2FactorAuthentication &&
    store.getters["userInfo/hasAnyRole"](Role.insight) &&
    !store.getters["userInfo/hasRegistered2fa"]
  ) {
    // If the user is already navigating to the 2FA registration page, allow it.
    // Otherwise, redirect them to the 2FA registration page.
    if (to.name === "page-register-2fa") {
      return next();
    } else {
      return next({
        name: "page-register-2fa",
      });
    }
  }

  // If a logged in user tries to reach the login or register page,
  // they are redirected to the home page.
  if (
    ([
      "page-login",
      "page-register",
      "page-login-2fa",
      "page-register-2fa",
    ].includes(to.name) &&
      !store.getters["userInfo/hasAnyRoles"](authorize)) ||
    (to.name === "page-register-2fa" &&
      store.getters["userInfo/hasAnyRole"](Role.insight))
  ) {
    return next({ name: "home", params: to.params });
  }

  if (authorize && !store.getters["userInfo/hasAnyRoles"](authorize)) {
    // First check if the user cannot access any product because of their role.
    // Go to 403 page if that's the case.
    const authorizedProductsForRole =
      store.getters["allProducts/getAllProductsAuthorizedForRole"];
    if (authorizedProductsForRole.length === 0) {
      return next({
        name: "page-error-403",
        params: {
          // It looks weird, but actually the route that we wanted to go to was `to`, but
          // we redirect to the 403 page, so the route we redirected from is indeed the `to`.
          redirectedFromPath: to.path,
        },
      });
    }

    // If not authorized by basic roles, check if we allowed when the user
    // is an admin of at least one product.
    const isAdminOfOne = config.products.some((product) =>
      store.getters["userInfo/isAdminOf"](product.productId)
    );
    if (!allowAdminOfOneProduct || !isAdminOfOne) {
      // If not even admin for the current product, check if the user is an admin of
      // at least one share class of the product.
      if (allowAdminOfOneShareClass && productId) {
        const shareClassesAdmin =
          store.getters["allProducts/getAllShareClassesAdmin"](productId);
        if (!shareClassesAdmin || shareClassesAdmin.length === 0) {
          return next({ name: "page-error-403" });
        }
      } else {
        return next({ name: "page-error-403" });
      }
    }
  }

  // In case we don't want to be bothered by the investor profile at all
  // we can bypass the rest of the verifications.
  if (
    !store.getters["platformSettings/getCompliance"](i18n.locale)?.[
      "investorProfileChecksActivated"
    ]
  ) {
    return next();
  }

  // Now that the roles are checked, we can see if we have investor profile
  // restrictions. If not, we can simply return the page the user wanted
  // to access. Note that if we are employees, we should be able to not care
  // about investor profile.
  if (
    to.meta.noNeedOfInvestorProfile ||
    store.getters["userInfo/hasAnyRoles"]([
      Role.superAdmin,
      Role.admin,
      Role.employee,
    ])
  ) {
    return next();
  }

  // We require to have the investor profile info (type and country)
  // of a user, so if we are missing those information, we request them.
  const userInfo = store.getters["userInfo/getUserInfo"];
  const investorProfileType =
    (userInfo && userInfo.investorProfile) ||
    store.getters["userInfo/getIpTypeBrowserStored"];
  const investorProfileCountry =
    (userInfo && userInfo.country) ||
    store.getters["userInfo/getIpCountryBrowserStored"];
  if (!investorProfileType || !investorProfileCountry) {
    // We need to get the investor profile (we don't have it), so go to
    // the page where the user has to choose it, and add the page we wanted to
    // go to as the "redirect" parameter to remember where we wanted to go.
    // Also don't forget to keep the current query parameters.
    const query = to.query;
    query["redirect"] = to.path;
    return next({
      name: "page-investor-profile",
      query: query,
    });
  }

  // Now that we are sure to have the investor profile info, we can check
  // if we should redirect the user to the unauthorized investor profile page.
  const isProductPage = to.meta && to.meta.productId;
  if (isProductPage) {
    // First check if the user cannot access this product because of their role.
    // Go to 403 page if that's the case.
    const authorizedProductsForRole =
      store.getters["allProducts/getAllProductsAuthorizedForRole"];
    const hasAccessFromRole = authorizedProductsForRole
      .map((product) => product.productId)
      .includes(to.meta.productId);
    if (!hasAccessFromRole) {
      return next({
        name: "page-error-403",
      });
    }

    // Then, check if the user cannot access this product because of their investor profile.
    // Go to the 'unauthorized page' if that's the case.
    const authorizedProductsForInvestorProfile =
      store.getters["allProducts/getAllProductsAuthorizedForInvestorProfile"];
    const hasAccessFromInvestorProfile = authorizedProductsForInvestorProfile
      .map((product) => product.productId)
      .includes(to.meta.productId);
    if (!hasAccessFromInvestorProfile) {
      return next({
        name: "page-unauthorized-investor-profile",
        params: {
          productId: to.meta.productId,
          unauthorizedPath: to.path,
        },
        query: to.query,
      });
    }
  } else {
    // If this is not a product page, we would like to verify if the user has access to at
    // least one other product, otherwise we would also redirect to the unauthorized investor
    // profile page.

    // First check if the user cannot access any product because of their role.
    // Go to 403 page if that's the case.
    const authorizedProductsForRole =
      store.getters["allProducts/getAllProductsAuthorizedForRole"];
    if (authorizedProductsForRole.length === 0) {
      return next({
        name: "page-error-403",
      });
    }

    // Then, check if the user cannot access any product because of their investor profile.
    // Go to the 'unauthorized page' if that's the case.
    const authorizedProductsForInvestorProfile =
      store.getters["allProducts/getAllProductsAuthorizedForInvestorProfile"];
    if (authorizedProductsForInvestorProfile.length == 0) {
      return next({
        name: "page-unauthorized-investor-profile",
        params: {
          unauthorizedPath: to.path,
        },
        query: to.query,
      });
    }
  }

  // If we reach this point, the role and investor profile restrictions are ok.
  next();
});

export default router;
