import { Role } from "@/acl/roles";
import { BONHOTE_PRODUCTS_DETAILS } from "@/assets/constants/products/bonhote";

export default {
  orgName: "Bonhôte",
  showHomePageHeader: false,
  navBarLogoStyle: "width: 100%; height: auto; max-height: 100px",
  allowedLoginMethods: ["Google"],
  hideLogoHomePage: true,
  background: {
    path: require("@/assets/images/background/bonhote.jpg"),
  },
  products: BONHOTE_PRODUCTS_DETAILS,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en", "fr", "de"],
  langInUrl: true,
  hideLoginRegisterButtons: true,
  colorScheme: [
    "#B20F21",
    "#CB5762",
    "#E4ABB2",
    "#F7E7E8",
    "#000000",
    "#666666",
    "#CCCCCC",
    "#F2F2F2",
  ],
};
