import components from "./components.js";
import pages from "./pages.js";
import wlps from "./wlps.js";

export default {
  apply: "Anwenden",
  asOf: (ctx) => `Stand ${ctx.named("date")}`,
  cancel: "Abbrechen",
  contactUs: "Kontaktieren Sie uns",
  edit: "Bearbeiten",
  error: "Fehler",
  from: "Von",
  ok: "Ok",
  loading: "Laden...",
  logIn: "Anmelden",
  logOut: "Abmelden",
  no: "Nein",
  others: "Andere",
  register: "Registrieren",
  save: "Speichern",
  saving: "Speichern...",
  serverError:
    "Auf unserem Server ist ein Fehler aufgetreten. Ein Bericht wurde an unser Team gesendet und wir werden ihn so schnell wie möglich beheben. Zögern Sie nicht, uns zu kontaktieren, wenn das Problem weiterhin besteht.",
  success: "Erfolg",
  to: "Bis",
  warning: "Warnung",
  yes: "Ja",
  investorProfileTypes: {
    institutional: "Institutioneller Anleger",
    professional: "Professioneller Anleger",
    retail: "Privatanleger",
  },
  layouts: {
    main: {
      dev: "Entwicklung",
      devTooltip: "Dieses Produkt befindet sich in der Entwicklung",
      admin: {
        listUsers: "Liste der Benutzer",
        operations: "Operationen",
        platformSettings: "Plattformeinstellungen",
        products: "Produkte",
        sectionName: "Admin",
      },
      help: {
        contact: "Kontakt",
        faq: "FAQ",
        knowledgeBase: "Wissensbasis",
        sectionName: "Hilfe",
      },
      products: {
        analysis: "Analyse",
        documents: "Dokumente",
        factsheet: "Factsheet",
        overview: "Übersicht",
        sectionName: "Produkte",
        useCase: "Anwendungsfall",
      },
    },
    fullPage: {
      backHome: "Zurück zur Startseite",
    },
    profileDropDown: {
      settings: "Einstellungen",
      dialog: {
        adminAsRegularOnMobile: {
          text: "Die Verwaltungsfunktionen sind auf mobilen Geräten nicht verfügbar. Um die Plattform vollständig nutzen zu können, verwenden Sie bitte einen Desktop-Computer. Zu den deaktivierten Verwaltungsfunktionen gehören unter anderem: Bearbeiten von Elementen in der Übersicht, Bearbeiten des Factsheets, Hochladen oder Löschen von Dokumenten und Zugriff auf den Verwaltungsbereich.",
          title: "Desktop-Computer für Verwaltungsfunktionen erforderlich",
        },
      },
    },
    theFooter: {
      allRightReserved: "Alle Rechte vorbehalten",
      copyright: "Urheberrecht",
      ombudsman: "Ombudsmann",
      privacyPolicy: "Datenschutzrichtlinie",
      termsOfUse: "Nutzungsbedingungen",
    },
    verticalNavMenu: {
      errorLoading: "Fehler beim Laden des Menüs",
    },
    verticalNavMenuGroup: {
      search: "Suche",
      showMore: "Mehr anzeigen",
    },
  },
  perfStats: {
    alpha: "Alpha",
    annualizedReturn: "Annualisierte Rendite",
    annualizedVolatility: "Annualisierte Volatilität",
    averageMonthlyReturns: "Durchschnittliche monatliche Erträge",
    averageNegativeMonthlyReturns:
      "Durchschnittliche negative monatliche Renditen",
    averagePositiveMonthlyReturns:
      "Durchschnittliche positive monatliche Renditen",
    beta: "Beta",
    calmarRatio: "Calmar Ratio",
    correlation: "Korrelation",
    cumulativeReturn: "Kumulative Rendite",
    informationRatio: "Information Ratio",
    kurtosis: "Kurtosis",
    m2RiskAdjustedPerfMonthly: "M2-Messung",
    maxConsecutiveNegativeMonthlyReturns:
      "Max. aufeinanderfolgende negative monatliche Renditen",
    maxConsecutivePositiveMonthlyReturns:
      "Max. aufeinanderfolgende positive monatliche Renditen",
    maxNegativeMonthlyReturn: "Maximale negative monatliche Rendite",
    maxPositiveMonthlyReturn: "Maximale positive monatliche Rendite",
    maximumDrawdown: "Maximaler Drawdown",
    omegaRatio: "Omega Ratio",
    ratioOfNegativeMonthlyReturns:
      "Verhältnis der negativen monatlichen Renditen",
    ratioOfPositiveMonthlyReturns:
      "Verhältnis der positiven monatlichen Renditen",
    returnToVolatilityRatio: "Rendite-Volatilitäts-Verhältnis",
    sharpeRatio: "Sharpe Ratio",
    skewness: "Schiefe",
    sortinoRatio: "Sortino Ratio",
    trackingError: "Tracking Error",
    treynorRatio: "Treynor Ratio",
    valueAtRiskOnePercentOneMonth: "Value at Risk (VaR) 1% über 1 Monat",
    valueAtRiskFivePercentOneMonth: "Value at Risk (VaR) 5% über 1 Monat",
  },
  validations: {
    afterDate: (ctx) =>
      ctx.named("nDaysApart") == 0
        ? `Datum muss nach ${ctx.named("minDate")} liegen (eingeschlossen)`
        : `Datum muss mindestens ${ctx.named(
            "nDaysApart"
          )} Tage nach ${ctx.named("minDate")}`,
    beforeDate: (ctx) =>
      ctx.named("nDaysApart") == 0
        ? `Datum muss vor ${ctx.named("maxDate")} liegen (eingeschlossen)`
        : `Das Datum muss mindestens ${ctx.named(
            "nDaysApart"
          )} Tage vor ${ctx.named("maxDate")}`,
    confirmed: "Das Feld stimmt nicht mit dem vorherigen überein.",
    dateFormat: "Das Datum muss im Format JJJJ-MM-TT angegeben werden.",
    email: "Die E-Mail ist ungültig.",
    maxFileSize: (ctx) =>
      `Die Datei ist größer als die maximal zulässige Größe (${(
        ctx.named("maxSizeBytes") /
        1000 /
        1000
      ).toFixed(0)} MB).`,
    maxChar: (ctx) =>
      `Das Feld darf nicht mehr als ${ctx.named("length")} Zeichen haben.`,
    minChar: (ctx) =>
      `Das Feld benötigt mindestens ${ctx.named("length")} Zeichen.`,
    minValue: (ctx) =>
      `Der Wert muss größer oder gleich ${ctx.named("min")} sein.`,
    phoneNumber: "Keine gültige Telefonnummer.",
    required: "Dieses Feld ist erforderlich.",
    roleFormat: 'Der Name der Rolle darf das Zeichen ":" nicht enthalten.',
  },
  wlps,
  pages,
  components,
};
