export default {
  alquant: {
    faq: {
      howToInvest: {
        question: "Wie kann ich in eines von Alquants Produkten investieren?",
        answer:
          'Da die finanzielle Situation und das Risikoprofil eines jeden Anlegers unterschiedlich sind, müssen wir Ihre Situation bewerten und prüfen, ob eines unserer Produkte für Sie geeignet ist. Um dies zu tun, verwenden Sie bitte <a href="/help/contact">unser Kontaktformular</a> oder schicken Sie uns eine E-Mail an <a href="mailto:info@alquant.com">info@alquant.com</a>, um eine Diskussion zu beginnen oder einen Anruf mit uns zu vereinbaren. Wir helfen Ihnen gerne weiter.',
      },
      moreQuestions: {
        question: "Ich habe eine weitere Frage, wo kann ich sie stellen?",
        answer:
          'Sie können uns jederzeit über <a href="/help/contact">unser Kontaktformular</a> kontaktieren, um Fragen zu stellen. Sie können innerhalb von 1-2 Werktagen eine Antwort von uns erwarten.',
      },
      whatAlquant: {
        question: "Was ist Alquant?",
        answer:
          'Alquant ist eine Schweizer Vermögensverwaltungsgesellschaft, die sich auf Risikomanagement spezialisiert hat und die traditionelle Vermögensverwaltung durch quantitative Finanzen und künstliche Intelligenz zum Vorteil ihrer Kunden verbessert. Wir sind bestrebt, überlegene Anlageergebnisse und herausragende Anlegererfahrungen zu erzielen. Weitere Informationen finden Sie auf unserer Website: <a href="https://alquant.com" target="_blank">https://alquant.com</a>.',
      },
      whatDataSources: {
        question: "Welche Datenquellen verwendet Alquant für seine Plattform?",
        answer:
          'Die Daten, die wir anzeigen, stammen hauptsächlich von unserem Hauptdatenanbieter, <a href="https://intrinio.com" target="_blank">Intrinio</a>. Für einige spezifische Daten verwenden wir auch andere Quellen, wie z.B. <a href="https://www.swissfunddata.ch/" target="_blank">Swiss Fund Data</a>, <a href="https://derivatives.juliusbaer.com/ target="_blank">Julius Baer</a>, <a href="https://keyinvest-ch-en.ubs.com/" target="_blank">UBS KeyInvest</a> und <a href="https://www.am.pictet/" target="_blank">Pictet Asset Management</a>.',
      },
      whatGoal: {
        question: "Was ist das Ziel dieser Plattform?",
        answer:
          "Da Alquant sich verpflichtet hat, eine hohe Transparenz und ein herausragendes Anlegererlebnis zu bieten, möchten wir, dass unsere Kunden Zugang zu interaktiven Diagrammen mit täglich aktualisierten Werten haben, anstatt langweilige PDF-Dokumente, die einmal im Monat erstellt werden, um zu sehen, wie unsere Produkte performen.",
      },
    },
    slogan: "Lösungen für den Erfolg von Anlegern.",
  },
  bcf: {
    redirectButtonNavBarText: "Zurück zur Startseite",
  },
  calmgard: {
    nextSubscriptionAndRedemption: {
      nextRedemption: (ctx) =>
        `<b>Rücknahmen</b>, die vor dem ${ctx.named(
          "date"
        )} um 12:00 Uhr MEZ erfolgen, werden zum letzten NAV des Monats ${ctx.named(
          "month"
        )} ausgeführt.`,
      nextSubscription: (ctx) =>
        `<b>Zeichnungen</b>, die vor dem ${ctx.named(
          "date"
        )} um 12:00 Uhr MEZ erfolgen, werden zum letzten NAV des Monats ${ctx.named(
          "month"
        )} ausgeführt.`,
      title: "Nächste mögliche Handelsdaten",
    },
  },
  cronos: {
    redirectButtonNavBarText: "Startseite Cronos",
  },
  demo: {
    customSections: {
      useCases: {
        title: "Anwendungsfälle",
        alpinum: {
          strategyName: "Alpinum",
          title: "Alpinum",
          historicalResults: {
            title: "Historische Ergebnisse",
            biggestDrawdowns: {
              title: (ctx) => `Größte Drawdowns von ${ctx.named("benchmark")}`,
            },
            drawdowns: {
              title: "Drawdowns",
            },
          },
          metaInfo: {
            title: "Anwendungsfall - Alpinum",
          },
          parameters: {
            runButton: "Anwendungsfall ausführen",
            benchmark: "Benchmark",
            title: "Parameter",
          },
        },
      },
    },
  },
  falgom: {
    taroDiversified: {
      useCase: {
        allocation: {
          equity: "Aktienportfolio",
          product: "Zuweisung an TARO® Diversified",
          title: "Portfoliozuweisung",
        },
        drawdowns: {
          title: "Drawdowns",
          biggestDrawdowns: "Größte Drawdowns",
        },
        historicalResults: {
          title: "Historische Ergebnisse",
        },
        metaInfo: {
          title: "Anwendungsfall - TARO® Diversified",
        },
      },
    },
  },
  mirante: {
    redirectButtonNavBarText: "Startseite MFM",
  },
  ppt: {
    redirectButtonNavBarUrl: "https://ppt.ch/en/asset-management/",
    redirectButtonNavBarText: "Zurück zur Hauptseite",
  },
};
