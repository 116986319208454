import { Role } from "@/acl/roles";
import { PPT_PRODUCTS_DETAILS } from "@/assets/constants/products/ppt";
import { i18n } from "@/main.js";

export default {
  orgName: "de Pury Pictet Turrettini",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/ppt.svg"),
  redirectButtonNavBar: {
    getUrl: () => i18n.t("wlps.ppt.redirectButtonNavBarUrl"),
    getText: () => i18n.t("wlps.ppt.redirectButtonNavBarText"),
    isInternal: false,
  },
  hideLogoHomePage: true,
  allowedLoginMethods: ["Google"],
  background: {
    path: require("@/assets/images/background/ppt.svg"),
  },
  products: PPT_PRODUCTS_DETAILS,
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en", "fr"],
  hideLoginRegisterButtons: true,
  colorScheme: [
    "#005950",
    "#007A6E",
    "#008F81",
    "#404040",
    "#7E7E7D",
    "#BABABA",
    "#DAC060",
    "#A22000",
    // Same colors with less opacity
    "#0059507F",
    "#007A6E7F",
    "#008F817F",
    "#4040407F",
    "#7E7E7D7F",
    "#BABABA7F",
    "#DAC0607F",
    "#A220007F",
  ],
};
