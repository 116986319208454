import { cloneDeep } from "lodash";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import {
  STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
  STANDARD_PERF_STATS_WITH_BENCHMARK,
} from "@/assets/constants/perfStats";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];

// ******---------- Global-bonds ----------*****
const globalBondsIa = {
  productId: "global-bonds-ia",
  storeModule: "globalBondsIa",
  productName: "Global-Bonds IA",
  productShortName: "Global-Bonds",
  productColor: ORG_COLOR,
  productShareClassRoot: "global-bonds",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const globalBondsId = cloneDeep(globalBondsIa);
globalBondsId.productId = "global-bonds-id";
globalBondsId.storeModule = "globalBondsId";
globalBondsId.productName = "Global-Bonds ID";
globalBondsId.isDefaultShareClass = false;

const globalBondsUhid = cloneDeep(globalBondsIa);
globalBondsUhid.productId = "global-bonds-uhid";
globalBondsUhid.storeModule = "globalBondsUhid";
globalBondsUhid.productName = "Global-Bonds UHID";
globalBondsUhid.isDefaultShareClass = false;

// ******---------- Global-Emerging ----------*****
const globalEmerging = {
  productId: "global-emerging",
  storeModule: "globalEmerging",
  productName: "Global-Emerging",
  productColor: ORG_COLOR,
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

// ******---------- Megatrend ----------*****
const megatrendI = {
  productId: "megatrend-i",
  storeModule: "megatrendI",
  productName: "Megatrend I",
  productShortName: "Megatrend",
  productColor: ORG_COLOR,
  productShareClassRoot: "megatrend",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const megatrendIh = cloneDeep(megatrendI);
megatrendIh.productId = "megatrend-ih";
megatrendIh.storeModule = "megatrendIh";
megatrendIh.productName = "Megatrend IH";
megatrendIh.isDefaultShareClass = false;

const megatrendIuh = cloneDeep(megatrendI);
megatrendIuh.productId = "megatrend-iuh";
megatrendIuh.storeModule = "megatrendIuh";
megatrendIuh.productName = "Megatrend IUH";
megatrendIuh.isDefaultShareClass = false;

// ******---------- Balanced ----------*****
const balanced = {
  productId: "balanced",
  storeModule: "balanced",
  productName: "Balanced",
  productColor: ORG_COLOR,
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

// ******---------- Impact ----------*****
const impactA = {
  productId: "impact-a",
  storeModule: "impactA",
  productName: "Impact A",
  productShortName: "Impact",
  productColor: ORG_COLOR,
  productShareClassRoot: "impact",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const impactIChf = cloneDeep(impactA);
impactIChf.productId = "impact-i-chf";
impactIChf.storeModule = "impactIChf";
impactIChf.productName = "Impact I CHF";
impactIChf.isDefaultShareClass = false;

const impactIEur = cloneDeep(impactA);
impactIEur.productId = "impact-i-eur";
impactIEur.storeModule = "impactIEur";
impactIEur.productName = "Impact I EUR";
impactIEur.isDefaultShareClass = false;

// ******---------- Strategic-Credit ----------*****
const strategicCreditChf = {
  productId: "strategic-credit-chf",
  storeModule: "strategicCredit",
  productName: "Strategic-Credit CHF",
  productShortName: "Strategic-Credit",
  productColor: ORG_COLOR,
  productShareClassRoot: "strategic-credit",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const strategicCreditUsd = cloneDeep(strategicCreditChf);
strategicCreditUsd.productId = "strategic-credit-usd";
strategicCreditUsd.storeModule = "strategicCreditUsd";
strategicCreditUsd.productName = "Strategic-Credit USD";
strategicCreditUsd.isDefaultShareClass = false;

const strategicCreditEur = cloneDeep(strategicCreditChf);
strategicCreditEur.productId = "strategic-credit-eur";
strategicCreditEur.storeModule = "strategicCreditEur";
strategicCreditEur.productName = "Strategic-Credit EUR";
strategicCreditEur.isDefaultShareClass = false;

// ******---------- BIM ----------*****
const bim = {
  productId: "bim",
  storeModule: "bim",
  productName: "BIM",
  productColor: ORG_COLOR,
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

// ******---------- Alternative ----------*****
const alternativeChf = {
  productId: "alternative-chf",
  storeModule: "alternativeChf",
  productName: "Alternative CHF",
  productShortName: "Alternative",
  productColor: ORG_COLOR,
  productShareClassRoot: "alternative",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const alternativeEur = cloneDeep(alternativeChf);
alternativeEur.productId = "alternative-eur";
alternativeEur.storeModule = "alternativeEur";
alternativeEur.productName = "Alternative EUR";
alternativeEur.isDefaultShareClass = false;

const alternativeUsd = cloneDeep(alternativeChf);
alternativeUsd.productId = "alternative-usd";
alternativeUsd.storeModule = "alternativeUsd";
alternativeUsd.productName = "Alternative USD";
alternativeUsd.isDefaultShareClass = false;

// ******---------- ZO-China ----------*****
const zoChinaAChf = {
  productId: "zo-china-a-chf",
  storeModule: "zoChinaAChf",
  productName: "ZO-China A CHF",
  productShortName: "ZO-China",
  productColor: ORG_COLOR,
  productShareClassRoot: "zo-china",
  isDefaultShareClass: true,
  showLastPrice: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const zoChinaAEur = cloneDeep(zoChinaAChf);
zoChinaAEur.productId = "zo-china-a-eur";
zoChinaAEur.storeModule = "zoChinaAEur";
zoChinaAEur.productName = "ZO-China A EUR";
zoChinaAEur.isDefaultShareClass = false;

const zoChinaAUsd = cloneDeep(zoChinaAChf);
zoChinaAUsd.productId = "zo-china-a-usd";
zoChinaAUsd.storeModule = "zoChinaAUsd";
zoChinaAUsd.productName = "ZO-China A USD";
zoChinaAUsd.isDefaultShareClass = false;

const zoChinaIChf = cloneDeep(zoChinaAChf);
zoChinaIChf.productId = "zo-china-i-chf";
zoChinaIChf.storeModule = "zoChinaIChf";
zoChinaIChf.productName = "ZO-China I CHF";
zoChinaIChf.isDefaultShareClass = false;

const zoChinaIEur = cloneDeep(zoChinaAChf);
zoChinaIEur.productId = "zo-china-i-eur";
zoChinaIEur.storeModule = "zoChinaIEur";
zoChinaIEur.productName = "ZO-China I EUR";
zoChinaIEur.isDefaultShareClass = false;

const zoChinaIUsd = cloneDeep(zoChinaAChf);
zoChinaIUsd.productId = "zo-china-i-usd";
zoChinaIUsd.storeModule = "zoChinaIUsd";
zoChinaIUsd.productName = "ZO-China I USD";
zoChinaIUsd.isDefaultShareClass = false;

export const BONHOTE_PRODUCTS_DETAILS = [
  globalBondsIa,
  globalBondsId,
  globalBondsUhid,
  globalEmerging,
  megatrendI,
  megatrendIh,
  megatrendIuh,
  balanced,
  impactA,
  impactIChf,
  impactIEur,
  strategicCreditChf,
  strategicCreditUsd,
  strategicCreditEur,
  bim,
  alternativeChf,
  alternativeEur,
  alternativeUsd,
  zoChinaAChf,
  zoChinaAEur,
  zoChinaAUsd,
  zoChinaIChf,
  zoChinaIEur,
  zoChinaIUsd,
];

export const BONHOTE_PRODUCTS = BONHOTE_PRODUCTS_DETAILS.map(
  (details) => details.storeModule
);
