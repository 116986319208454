/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations } from "vuexfire";
import { cloneDeep } from "lodash";
// import progressiveLoader from "./plugins/progressiveLoader";

import { ALQUANT_PRODUCTS } from "@/assets/constants/products/alquant";
import { BANCA_CREDINVEST_PRODUCTS } from "@/assets/constants/products/banca-credinvest";
import { BCF_PRODUCTS } from "@/assets/constants/products/bcf";
import { BI_CAPITAL_PRODUCTS } from "@/assets/constants/products/bi-capital";
import { BONHOTE_PRODUCTS } from "@/assets/constants/products/bonhote";
import { CALMGARD_PRODUCTS } from "@/assets/constants/products/calmgard";
import { CGE_TECH_CARE_PRODUCTS } from "@/assets/constants/products/cge-tech-care";
import { CHAMELEON_PRODUCTS } from "@/assets/constants/products/chameleon";
import { CITE_GESTION_PRODUCTS } from "@/assets/constants/products/cite-gestion";
import { CRONOS_PRODUCTS } from "@/assets/constants/products/cronos";
import { DEMO_PRODUCTS } from "@/assets/constants/products/demo";
import { EQUINOX_PRODUCTS } from "@/assets/constants/products/equinox";
import { FALGOM_PRODUCTS } from "@/assets/constants/products/falgom";
import { FINANZLAB_PRODUCTS } from "@/assets/constants/products/finanzlab";
import { FORUM_CAPITAL_PRODUCTS } from "@/assets/constants/products/forum-capital";
import { LAKEFIELD_PRODUCTS } from "@/assets/constants/products/lakefield";
import { MIRANTE_PRODUCTS } from "@/assets/constants/products/mirante";
import { MSM_PRODUCTS } from "@/assets/constants/products/msm";
import { ONE_SWISS_BANK_PRODUCTS } from "@/assets/constants/products/one-swiss-bank";
import { OPENFUNDS_PRODUCTS } from "@/assets/constants/products/openfunds";
import { SIXT_PRODUCTS } from "@/assets/constants/products/6t";
import { PPT_PRODUCTS } from "@/assets/constants/products/ppt";
import { SSI_PRODUCTS } from "@/assets/constants/products/ssi";
import { UTI_PRODUCTS } from "@/assets/constants/products/uti";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

// All products
import moduleAllProducts from "./modules/allProducts.js";

// Assets
import moduleAssets from "./modules/assets.js";

// Auth
import moduleAuth from "./auth/moduleAuth.js";

// Benchmarks
import moduleBenchmarks from "./modules/benchmarks.js";

// Key numbers
import moduleKeyNumbers from "./modules/keyNumbers";

// Platform settings
import modulePlatformSettings from "./modules/platformSettings.js";

// Settings
import moduleSettings from "./modules/settings.js";

// User information
import moduleUserInfo from "./modules/userInfo.js";

// Users
import moduleUsers from "./modules/users.js";

class ImportError extends Error {}

/* CUSTOM SECTIONS */

let moduleCustomSections;
if (process.env.VUE_APP_ORG_ID == "demo") {
  moduleCustomSections =
    require(`@/store/modules/customSections/demo.js`).default;
}

/* PRODUCTS */

// Create products modules
const loadProductModule = (productName) => {
  // First, try to see if there is a special module for this product and return it.
  // If not, try to see if there is a special module for this organisation and return it.
  // If not, raise an ImportError (that will be caught above).
  try {
    return require(`@/store/modules/orgs/${process.env.VUE_APP_ORG_ID}/products/${productName}.js`)
      .default;
  } catch (e) {
    try {
      return require(`@/store/modules/orgs/${process.env.VUE_APP_ORG_ID}/products.js`)
        .default;
    } catch (e) {
      throw new ImportError(`Unable to import product module '${productName}'`);
    }
  }
};
const orgToProducts = {
  alquant: ALQUANT_PRODUCTS,
  "banca-credinvest": BANCA_CREDINVEST_PRODUCTS,
  bcf: BCF_PRODUCTS,
  "bi-capital": BI_CAPITAL_PRODUCTS,
  bonhote: BONHOTE_PRODUCTS,
  calmgard: CALMGARD_PRODUCTS,
  "cge-tech-care": CGE_TECH_CARE_PRODUCTS,
  chameleon: CHAMELEON_PRODUCTS,
  "cite-gestion": CITE_GESTION_PRODUCTS,
  cronos: CRONOS_PRODUCTS,
  demo: DEMO_PRODUCTS,
  equinox: EQUINOX_PRODUCTS,
  falgom: FALGOM_PRODUCTS,
  finanzlab: FINANZLAB_PRODUCTS,
  "forum-capital": FORUM_CAPITAL_PRODUCTS,
  lakefield: LAKEFIELD_PRODUCTS,
  mirante: MIRANTE_PRODUCTS,
  msm: MSM_PRODUCTS,
  "one-swiss-bank": ONE_SWISS_BANK_PRODUCTS,
  openfunds: OPENFUNDS_PRODUCTS,
  "6t": SIXT_PRODUCTS,
  ppt: PPT_PRODUCTS,
  ssi: SSI_PRODUCTS,
  uti: UTI_PRODUCTS,
};
const allProducts = orgToProducts[process.env.VUE_APP_ORG_ID];
const allProductsModules = allProducts.map((productName) => {
  try {
    let moduleProduct = cloneDeep(loadProductModule(productName));
    return [productName, moduleProduct];
  } catch (err) {
    // If no special product module found, load the generic product module.
    if (err instanceof ImportError) {
      // Need to deep clone otherwise the different modules will share the same state.
      let moduleProduct = cloneDeep(
        require("@/store/modules/products.js").default
      );
      return [productName, moduleProduct];
    } else {
      throw err;
    }
  }
});
const mapProductsModules = new Map(allProductsModules);

const modules = {
  allProducts: moduleAllProducts,
  assets: moduleAssets,
  auth: moduleAuth,
  benchmarks: moduleBenchmarks,
  keyNumbers: moduleKeyNumbers,
  platformSettings: modulePlatformSettings,
  settings: moduleSettings,
  userInfo: moduleUserInfo,
  users: moduleUsers,
  ...Object.fromEntries(mapProductsModules),
};

// Only add custom sections module if defined.
if (moduleCustomSections) {
  modules["customSections"] = moduleCustomSections;
}

export default new Vuex.Store({
  getters,
  mutations: {
    ...mutations,
    ...vuexfireMutations,
  },
  state,
  actions,
  modules,
  strict: process.env.NODE_ENV !== "production",
  // plugins: [progressiveLoader]
});
